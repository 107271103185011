import {
  CliftReducerAction,
  useCliftContext,
} from "../../context/clift-context/clift-context";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import SideModal from "../../components/SideModal";
import { CustomerForm } from "./CustomerForm";
import { CustomerFormValues } from "../../models/customer";
import { createCustomer } from "../../services/customers-api";
import { getErrorCode } from "../../services/common";
import { Contact } from "../../models/contact";

export const CreateCustomer = () => {
  const { cliftState, dispatchCliftState } = useCliftContext();
  const [loading, setLoading] = useState(false);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const { t } = useTranslation();

  if (loading) return <progress></progress>;

  const closeSidePanel = () => {
    dispatchCliftState({
      type: CliftReducerAction.CloseSidePanel,
    });
  };

  const onCreateCustomer = (
    customer: CustomerFormValues,
    contacts: Contact[],
  ) => {
    if (!cliftState.currentTenant) throw new Error("No current tenant");

    setLoading(true);
    const tenantId = cliftState.currentTenant.id;

    return createCustomer(customer, tenantId, contacts)
      .then(() => {
        setLoading(false);
        dispatchCliftState({
          type: CliftReducerAction.FetchCustomers,
        });
        closeSidePanel();
      })
      .catch((err) => {
        setLoading(false);
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("create_customer_http_fail", {
            ns: "alerts",
            code: getErrorCode(err),
          }),
        });
      });
  };

  return (
    <SideModal
      title={
        currentPageIndex === 0
          ? t("add_customer_title", { ns: "customer" })
          : t("add_contacts_title", { ns: "customer" })
      }
      pageCount={2}
      pageIndex={currentPageIndex}
      onClose={closeSidePanel}
    >
      <CustomerForm
        pageIndex={currentPageIndex}
        onSubmit={onCreateCustomer}
        onClose={closeSidePanel}
        onSwitchPage={setCurrentPageIndex}
      />
    </SideModal>
  );
};
