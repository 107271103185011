import { useParams } from "react-router-dom";
import Card, {
  CardAddressInfo,
  CardContainer,
  CardDetail,
  CardGroup,
  CardType,
} from "../../components/Card";
import Page from "../../components/Page";
import { CustomerFullResponse } from "models/customer";
import { useEffect, useState } from "react";
import { fetchCustomer } from "../../services/customers-api";
import {
  CliftReducerAction,
  useCliftContext,
} from "../../context/clift-context/clift-context";
import { getErrorCode } from "../../services/common";
import { ReactComponent as ProfileIcon } from "../../assets/Profile.svg";
import { ReactComponent as BuildingIcon } from "../../assets/Building.svg";
import { CustomerDetailDevices } from "./CustomerDetailDevices";
import { NoItems } from "../../components/NoItems";
import { useTranslation } from "react-i18next";
import { CreateCustomerPages } from "./CustomerForm";
import { ContactCommonResponse } from "models/contact";
import "./CustomerDetail.css";

export const CustomerDetail = () => {
  const { cliftState, dispatchCliftState } = useCliftContext();
  const handeEditCustomerInfo = () => {
    dispatchCliftState({
      type: CliftReducerAction.EditCustomer,
      customer: customer,
      customerID: getCustomerId()!,
      page: CreateCustomerPages.CustomerPage,
    });
  };
  const handleEditContacts = () => {
    dispatchCliftState({
      type: CliftReducerAction.EditCustomer,
      customer: customer,
      customerID: getCustomerId()!,
      page: CreateCustomerPages.CustomerContactsPage,
    });
  };
  const handleEditLifts = () => {
    if (customer) {
      dispatchCliftState({
        type: CliftReducerAction.AddCustomerLifts,
        customer,
      });
    }
  };

  const params = useParams();
  const [customer, setCustomer] = useState<CustomerFullResponse>();
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const getCustomerId = () => {
    const customerID =
      params.customerID !== undefined ? parseInt(params.customerID) : undefined;
    return customerID !== undefined && !isNaN(customerID)
      ? customerID
      : undefined;
  };

  const getTitle = () => {
    if (customer) {
      return customer.organization
        ? customer.organization
        : `${customer.firstName} ${customer.lastName}`;
    }
    return "";
  };

  const getContactDetails = (contact: ContactCommonResponse) => {
    return (
      <>
        <div className="customer-detail-contact-row">
          <div>{contact.displayName}</div>
          <div>{contact.phoneNr}</div>
        </div>
      </>
    );
  };

  useEffect(() => {
    const customerID = getCustomerId();
    if (customerID !== undefined && !cliftState.sidepanelContent) {
      setLoading(true);
      fetchCustomer(customerID)
        .then((res) => {
          setLoading(false);
          setCustomer(res);
        })
        .catch((err) => {
          setLoading(false);
          dispatchCliftState({
            type: CliftReducerAction.AddAlert,
            alert: t("fetch_customer_http_fail", {
              ns: "alerts",
              code: getErrorCode(err),
            }),
          });
        });
    }
  }, [params.customerID, cliftState.sidepanelContent]);

  const handleLiftSelected = (_index: number) => {
    // TODO Open lift details page
  };

  if (loading) {
    return <progress></progress>;
  }
  return (
    <Page title={getTitle()} subtitle={customer?.vat ?? ""}>
      {customer && (
        <CardContainer>
          <Card
            title={t("customer_information", { ns: "customer" })}
            onEdit={handeEditCustomerInfo}
          >
            <CardGroup title={t("personal_information", { ns: "customer" })}>
              <CardDetail
                icon={<ProfileIcon />}
                value={`${customer?.firstName} ${customer?.lastName}`}
              />
              <CardDetail
                icon={<BuildingIcon />}
                value={customer?.organization}
              />
            </CardGroup>
            <CardGroup title={t("contact_information", { ns: "customer" })}>
              <CardDetail icon={<ProfileIcon />} value={customer?.email} />
              <CardDetail icon={<ProfileIcon />} value={customer?.phoneNr} />
              <CardAddressInfo
                street={customer.address}
                city={customer.city}
                zip={customer.zipCode}
                state={customer.region}
                country={customer.country}
                useHeader={false}
              ></CardAddressInfo>
            </CardGroup>
          </Card>
          <Card
            title={t("customer_contacts", { ns: "customer" })}
            onEdit={handleEditContacts}
          >
            {customer?.contacts &&
              customer?.contacts.map((contact, i) => (
                <CardDetail
                  key={i}
                  icon={<ProfileIcon />}
                  value={getContactDetails(contact)}
                />
              ))}
          </Card>
          <Card
            title={t("customer_lifts", { ns: "customer" })}
            cardType={CardType.DOUBLE}
            onEdit={handleEditLifts}
          >
            {customer.devices !== null && customer.devices.length > 0 ? (
              <CustomerDetailDevices
                devices={customer.devices ?? []}
                onItemSelected={handleLiftSelected}
              />
            ) : (
              <NoItems
                title={t("no_lifts_added", { ns: "customer" })}
                subtitle={t("no_lifts_added_hint", { ns: "customer" })}
                button={
                  <button
                    className="button"
                    type="button"
                    onClick={handleEditLifts}
                  >
                    {t("add_new_lift_btn", { ns: "customer" })}
                  </button>
                }
              />
            )}
          </Card>
        </CardContainer>
      )}
    </Page>
  );
};
