import FormField from "../../components/FormField";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as PlusIcon } from "../../assets/Plus.svg";
import { ReactComponent as DeleteIcon } from "../../assets/Delete.svg";
import {
  CustomerDeviceFormValues,
  CustomerFullResponse,
} from "../../models/customer";
import "./AddCustomerLiftsForm.css";
import { AddCustomerLiftsConfirm } from "./AddCustomerLiftsConfirm";

export interface AddCustomerLiftsFormProps {
  editingCustomer: CustomerFullResponse;
  error: string | undefined;
  initialDevices?: CustomerDeviceFormValues[];
  onSubmit: (
    customer: CustomerFullResponse,
    devices: CustomerDeviceFormValues[],
  ) => void;
  onClose: () => void;
}

export const AddCustomerLiftsForm = ({
  editingCustomer,
  error,
  initialDevices = [],
  onSubmit,
  onClose,
}: AddCustomerLiftsFormProps) => {
  const { t } = useTranslation();
  const [devices, setDevices] =
    useState<CustomerDeviceFormValues[]>(initialDevices);
  const [showConfirm, setShowConfirm] = useState(false);
  const addCustomerLiftForm = useRef<HTMLFormElement>(null);
  const newLiftSerial = useRef<HTMLInputElement>(null);
  const newLiftNick = useRef<HTMLInputElement>(null);

  const getErrorText = (errorCode: string | number) => {
    if (errorCode === 404) {
      return t("device_not_found", { ns: "customer" });
    } else {
      return t("adding_device_failed", {
        ns: "customer",
        code: errorCode,
      });
    }
  };

  return (
    <div className="add-customer-lifts-form">
      <form
        ref={addCustomerLiftForm}
        onSubmit={(e) => {
          e.preventDefault();
          setShowConfirm(true);
        }}
      >
        <div className="add-customer-lifts-form-page">
          <div className="add-customer-lifts-form-creation-row">
            <div>
              <h3>{t("add_lifts_subtilte", { ns: "customer" })}</h3>
              <h4>{t("add_lifts_hint", { ns: "customer" })}</h4>
              <div>{t("add_lifts_note", { ns: "customer" })}</div>
            </div>

            <button
              className="button"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setDevices([
                  ...devices,
                  {
                    serialNumber: newLiftSerial.current!.value,
                    nickname: newLiftNick.current!.value,
                  },
                ]);
                addCustomerLiftForm.current?.reset();
              }}
            >
              <PlusIcon />
              {t("add_new_lift_btn", { ns: "customer" })}
            </button>
          </div>

          <div className="add-customer-lifts-form-creation-input-row">
            <FormField
              title={t("x06_serial_number", { ns: "customer" })}
              name="serialNumber"
              ref={newLiftSerial}
            />
            <FormField
              title={t("lift_name_or_model", { ns: "customer" })}
              name="nickname"
              optional
              ref={newLiftNick}
            />
            <button
              className="add-customer-lifts-form-delete-btn"
              title={t("delete", { ns: "translation" })}
              type="button"
              onClick={(e) => {
                e.preventDefault();
                addCustomerLiftForm.current?.reset();
              }}
            >
              <DeleteIcon />
            </button>
          </div>

          {devices.map((device, i) => (
            <div key={i} className="add-customer-lifts-form-creation-input-row">
              <FormField
                title={t("x06_serial_number", { ns: "customer" })}
                name="serialNumber"
                disabled
                value={device.serialNumber}
              />
              <FormField
                title={t("lift_name_or_model", { ns: "customer" })}
                name="nickname"
                disabled
                value={device.nickname}
              />
              <button
                className="add-customer-lifts-form-delete-btn"
                title={t("delete", { ns: "translation" })}
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  if (devices) setDevices(devices.toSpliced(i, 1));
                }}
              >
                <DeleteIcon />
              </button>
            </div>
          ))}

          <div className="add-customer-lifts-form-errors">
            {error && getErrorText(error)}
          </div>

          <div className="add-customer-lifts-form-button-panel">
            <button
              className="button"
              onClick={(e) => {
                e.preventDefault();
                onClose();
              }}
            >
              {t("cancel")}
            </button>
            <button
              type="submit"
              className="button"
              id="submit_button"
              disabled={!devices.length}
            >
              {t("save", { ns: "customer" })}
            </button>
          </div>

          <AddCustomerLiftsConfirm
            isOpen={showConfirm}
            devices={devices}
            onConfirm={() => {
              setShowConfirm(false);
              onSubmit(editingCustomer, devices);
            }}
            onCancel={() => {
              setShowConfirm(false);
            }}
          />
        </div>
      </form>
    </div>
  );
};
