export const enum ContactType {
  CUSTOMER_CONTACT = "CUSTOMER_CONTACT",
  SUPPORT = "SUPPORT",
  EMERGENCY = "EMERGENCY",
}

export type Contact = {
  id?: number;
  displayName: string;
  firstName: string;
  lastName: string;
  phoneNr: string;
  contactType: ContactType;
};

export type ContactCommonResponse = {
  displayName: string;
  firstName: string;
  lastName: string;
  phoneNr: string;
  contactType: ContactType;
};
