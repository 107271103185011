import {
  CliftReducerAction,
  useCliftContext,
} from "../../context/clift-context/clift-context";
import editIcon from "../../assets/Edit.svg";
import deleteIcon from "../../assets/Delete.svg";
import { useTranslation } from "react-i18next";
import { TenantListItem } from "../../models/tenant-list-item";

export const TenantOptionsMenu = ({
  tenant,
  setSelectedTenant,
}: {
  tenant: TenantListItem;
  setSelectedTenant(tenant: TenantListItem): void;
}) => {
  const { dispatchCliftState, hasTenantRole } = useCliftContext();
  const { t } = useTranslation();

  return (
    <>
      <div className="dropdown" id={"tenant_" + tenant.id}>
        <button className="dropbutton">...</button>
        <div className="dropdown-items">
          {hasTenantRole([
            tenant.id.toString(),
            tenant.parentTenantId?.toString(),
          ]) && (
            <p
              className="link"
              onClick={() => {
                dispatchCliftState({
                  type: CliftReducerAction.EditTenant,
                  tenantID: tenant.id,
                });
              }}
            >
              <img alt="" className="edit-icon" src={editIcon} />
              {t("edit")}
            </p>
          )}
          {hasTenantRole([
            tenant.id.toString(),
            tenant.parentTenantId?.toString(),
          ]) && (
            <p
              className="link"
              onClick={() => {
                setSelectedTenant(tenant);
              }}
            >
              <img alt="Delete" className="delete-icon" src={deleteIcon} />
              {t("delete_tenant", { ns: "tenant" })}
            </p>
          )}
        </div>
      </div>
    </>
  );
};
