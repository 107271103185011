import { User } from "../models/user";
import { CliftApiError, handleError } from "./common";

export const getTenantUsers = (tenantId: number): Promise<User[]> =>
  fetch("/api/users?tenant=" + tenantId, {
    method: "GET",
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw new CliftApiError(res.status);
      }
    })
    .catch((e) => handleError(e));

export const createUser = (tenantId: number, name: string, email: string) =>
  fetch("/api/users", {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify({
      tenant: { id: tenantId },
      name,
      email,
    }),
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw new CliftApiError(res.status);
      }
    })
    .catch((e) => handleError(e));
