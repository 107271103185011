import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TenantList } from "./TenantList";
import {
  CliftReducerAction,
  useCliftContext,
} from "../../context/clift-context/clift-context";
import Page from "../../components/Page";
import { ReactComponent as PlusIcon } from "../../assets/Plus.svg";
import { ConfirmDelete } from "../../components/ConfirmDelete";
import { deleteTenant } from "../../services/tenants-api";
import { getErrorCode } from "../../services/common";
import { TenantListItem } from "../../models/tenant-list-item";
import { TenantType } from "../../models/tenant";

export const TenantsPage = () => {
  const { t } = useTranslation();
  const { currentUser, cliftState, dispatchCliftState } = useCliftContext();
  const [updateView, setUpdateView] = useState(false);
  const [selectedTenant, setSelectedTenant] = useState<TenantListItem>();

  const isAdminRole = currentUser.clientPrincipal?.userRoles?.some(
    (role) => role === `TENANT_ADMIN_${cliftState.currentTenant?.id}`,
  );

  useEffect(() => {
    if (cliftState.currentTenant) {
      dispatchCliftState({
        type: CliftReducerAction.FetchTenants,
      });

      dispatchCliftState({
        type: CliftReducerAction.FetchChildTenants,
        tenantID: cliftState.currentTenant.id,
      });
    }
  }, [cliftState.currentTenant, updateView]);

  if (!cliftState.currentTenant) {
    return <></>;
  }

  if (!cliftState.childTenants) {
    return <progress></progress>;
  }

  const handleDeleteTenant = async () => {
    if (!selectedTenant) return;

    await deleteTenant(selectedTenant?.id).catch((err) => {
      dispatchCliftState({
        type: CliftReducerAction.AddAlert,
        alert: t("delete_tenant_http_fail", {
          ns: "alerts",
          code: getErrorCode(err),
        }),
      });
    });

    setSelectedTenant(undefined);
    setUpdateView(!updateView);
  };

  return (
    <Page
      title={
        cliftState.currentTenant?.tenantType === TenantType.ROOT
          ? t("markets", { ns: "tenant" })
          : t("tenants", { ns: "tenant" })
      }
      subtitle={
        cliftState.currentTenant?.tenantType === TenantType.ROOT
          ? t("markets_subtitle", { ns: "tenant" })
          : t("tenants_subtitle", { ns: "tenant" })
      }
      button={
        isAdminRole ? (
          <button
            className="button"
            type="button"
            id="new_tenant_btn"
            onClick={() => {
              dispatchCliftState({
                type: CliftReducerAction.AddTenant,
              });
            }}
          >
            <PlusIcon />

            {cliftState.currentTenant?.tenantType === "ROOT"
              ? t("new_market_btn", { ns: "tenant" })
              : t("new_tenant_btn", { ns: "tenant" })}
          </button>
        ) : null
      }
    >
      <>
        {typeof cliftState.currentTenant !== "undefined" ? (
          <TenantList
            parentTenant={cliftState.currentTenant.id}
            tenants={cliftState.childTenants}
            setSelectedTenant={(tenant: TenantListItem | undefined) => {
              setSelectedTenant(tenant);
            }}
          />
        ) : (
          <div></div>
        )}

        {selectedTenant && (
          <ConfirmDelete
            title={t("delete_tenant_question", { ns: "dialogs" })}
            text={t("confirm_tenant_delete_question", {
              ns: "dialogs",
              tenant: selectedTenant.id,
            })}
            isOpen={!!selectedTenant}
            onCancel={() => setSelectedTenant(undefined)}
            onConfirmed={handleDeleteTenant}
          />
        )}
      </>
    </Page>
  );
};
