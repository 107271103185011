import { CreateTenant } from "../../app/tenants/CreateTenant";
import { CreateCustomer } from "../../app/customers/CreateCustomer";
import { EditCustomer } from "../../app/customers/EditCustomer";
import { EditTenant } from "../../app/tenants/EditTenant";
import {
  Context,
  ReactNode,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { fetchTenants } from "../../services/tenants-api";
import { User } from "../../models/user";
import { Customer } from "../../models/customer";
import { ClientPrincipal } from "../../models/client-principal";
import { getTenantUsers } from "../../services/users-api";
import { getErrorCode } from "../../services/common";
import { fetchCustomers } from "../../services/customers-api";
import { fetchContacts } from "../../services/contacts-api";
import { Contact } from "../../models/contact";
import { CustomerListItem } from "../../models/customer-list-item";
import { CustomerFullResponse } from "../../models/customer";
import { AddCustomerLifts } from "../../app/customers/AddCustomerLifts";
import {
  PersistKey,
  getPersistentItem,
  setPersistentItem,
} from "../../services/persistent";
import { TenantListItem } from "../../models/tenant-list-item";
import { TenantResponseFull } from "../../models/tenant";
import { AddTenantContacts } from "../../app/tenants/AddTenantContacts";

export const context = React.createContext<CliftContext | undefined>(
  undefined,
) as Context<CliftContext>;

export const useCliftContext = () => useContext(context);
export const enum CliftReducerAction {
  FetchTenants,
  FetchChildTenants,
  FetchTenantUsers,
  SetCurrentTenant,
  SetTenants,
  SetChildTenants,
  AddAlert,
  EditTenant,
  EditTenantContacts,
  AddUsers,
  AddTenant,
  FetchCustomers,
  SetCurrentCustomer,
  SetCustomers,
  AddCustomer,
  EditCustomer,
  AddCustomerLifts,
  FetchContacts,
  SetContacts,
  CloseSidePanel,
  AlertPanelVisible,
  UserPanelVisible,
}

export const enum Panels {
  Alerts = 1,
  Settings = 2,
  User = 4,
}

export type CliftContextState = {
  currentTenant: TenantListItem | undefined;
  tenants: TenantListItem[];
  childTenants: TenantListItem[] | undefined;
  alerts: string[];
  currentCustomer: Customer | undefined;
  customers: CustomerListItem[] | undefined;
  users: User[];
  contacts: Contact[];
  sidepanelContent: ReactNode | undefined;
  tenantFetch: { [index: number]: Promise<void> };
  visiblePanels: number;
};

type actions =
  | {
      type: CliftReducerAction.FetchTenants;
    }
  | {
      type: CliftReducerAction.FetchChildTenants;
      tenantID: number;
    }
  | {
      type: CliftReducerAction.SetCurrentTenant;
      tenant: TenantListItem;
    }
  | {
      type: CliftReducerAction.SetTenants;
      tenants: TenantListItem[];
    }
  | {
      type: CliftReducerAction.SetChildTenants;
      tenants: TenantListItem[];
    }
  | {
      type: CliftReducerAction.AddAlert;
      alert: string;
    }
  | {
      type: CliftReducerAction.EditTenant;
      tenantID: number | undefined;
      page?: number;
    }
  | {
      type: CliftReducerAction.EditTenantContacts;
      tenant: TenantResponseFull;
    }
  | {
      type: CliftReducerAction.AddUsers;
      users: User[];
    }
  | {
      type: CliftReducerAction.FetchTenantUsers;
      tenantID: number;
    }
  | {
      type: CliftReducerAction.AddTenant;
    }
  | {
      type: CliftReducerAction.FetchCustomers;
    }
  | {
      type: CliftReducerAction.SetCurrentCustomer;
      customer: Customer;
    }
  | {
      type: CliftReducerAction.SetCustomers;
      customers: CustomerListItem[];
    }
  | {
      type: CliftReducerAction.AddCustomer;
    }
  | {
      type: CliftReducerAction.EditCustomer;
      customer: Customer | undefined;
      customerID: number;
      page?: number;
    }
  | {
      type: CliftReducerAction.AddCustomerLifts;
      customer: CustomerFullResponse;
    }
  | {
      type: CliftReducerAction.FetchContacts;
      customerID: number;
    }
  | {
      type: CliftReducerAction.SetContacts;
      contacts: Contact[];
    }
  | {
      type: CliftReducerAction.FetchContacts;
      customerID: number;
    }
  | {
      type: CliftReducerAction.SetContacts;
      contacts: Contact[];
    }
  | {
      type: CliftReducerAction.CloseSidePanel;
    }
  | {
      type: CliftReducerAction.AlertPanelVisible;
      visible: boolean;
    }
  | {
      type: CliftReducerAction.UserPanelVisible;
      visible: boolean;
    };

export type CliftContext = {
  currentUser: ClientPrincipal;
  createTenantPanelVisible: boolean;
  setCreateTenantPanelVisible: (value: boolean) => void;
  hasTenantRole: (tenantIds: (string | undefined)[]) => boolean | undefined;
  cliftState: CliftContextState;
  dispatchCliftState: React.Dispatch<actions>;
};

export const CliftContextProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const { Provider } = context;

  const [currentUser, setCurrentUser] = useState<ClientPrincipal>({
    clientPrincipal: null,
  });

  const [createTenantPanelVisible, setCreateTenantPanelVisible] =
    useState(false);
  const { t } = useTranslation();

  const hasTenantRole = (tenantIds: (string | undefined)[]): boolean => {
    return tenantIds.some((tenantId) =>
      currentUser.clientPrincipal?.userRoles?.includes(
        "TENANT_ADMIN_" + tenantId,
      ),
    );
  };

  function cliftReducer(
    state: CliftContextState,
    action: actions,
  ): CliftContextState {
    switch (action.type) {
      case CliftReducerAction.FetchTenantUsers:
        getTenantUsers(action.tenantID)
          .then((res) => {
            dispatchCliftState({
              type: CliftReducerAction.AddUsers,
              users: res,
            });
          })
          .catch((err) => {
            dispatchCliftState({
              type: CliftReducerAction.AddAlert,
              alert: t("users_list_http_fail", {
                ns: "alerts",
                code: getErrorCode(err),
              }),
            });
          });

        return {
          ...state,
        };
      case CliftReducerAction.FetchTenants:
        fetchTenants()
          .then((res) => {
            dispatchCliftState({
              type: CliftReducerAction.SetTenants,
              tenants: res,
            });
          })
          .catch((err) => {
            dispatchCliftState({
              type: CliftReducerAction.AddAlert,
              alert: t("tenants_list_http_fail", {
                ns: "alerts",
                code: getErrorCode(err),
              }),
            });
          });

        return {
          ...state,
        };
      case CliftReducerAction.FetchChildTenants:
        fetchTenants(action.tenantID)
          .then((res) => {
            dispatchCliftState({
              type: CliftReducerAction.SetChildTenants,
              tenants: res,
            });
          })
          .catch((err) => {
            dispatchCliftState({
              type: CliftReducerAction.SetChildTenants,
              tenants: [],
            });
            dispatchCliftState({
              type: CliftReducerAction.AddAlert,
              alert: t("tenants_list_http_fail", {
                ns: "alerts",
                code: getErrorCode(err),
              }),
            });
          });

        return {
          ...state,
        };

      case CliftReducerAction.SetTenants: {
        let currentTenant = state.currentTenant;
        if (!currentTenant) {
          const savedTenantID = getPersistentItem(PersistKey.CURRENT_TENANT_ID);
          if (savedTenantID) {
            currentTenant = action.tenants.find(
              (tenant) => tenant.id.toString() === savedTenantID,
            );
          }
        }

        currentTenant = currentTenant ?? action.tenants[0];

        if (currentTenant) {
          setPersistentItem(
            PersistKey.CURRENT_TENANT_ID,
            currentTenant.id.toString(),
          );
        }

        return {
          ...state,
          currentTenant: currentTenant,
          tenants: Object.values({
            ...Object.fromEntries(
              action.tenants.map((tenant) => [tenant.id, tenant]),
            ),
          }),
        };
      }
      case CliftReducerAction.SetChildTenants:
        return {
          ...state,
          childTenants: Object.values({
            ...Object.fromEntries(
              action.tenants.map((tenant) => [tenant.id, tenant]),
            ),
          }),
        };
      case CliftReducerAction.SetCurrentTenant:
        return {
          ...state,
          currentTenant: action.tenant,
          childTenants: undefined,
          customers: undefined,
        };
      case CliftReducerAction.AddAlert:
        return {
          ...state,
          alerts: [...state.alerts, action.alert],
          visiblePanels: Panels.Alerts,
        };
      case CliftReducerAction.AlertPanelVisible:
        return {
          ...state,
          visiblePanels: action.visible
            ? Panels.Alerts
            : state.visiblePanels & ~Panels.Alerts,
        };
      case CliftReducerAction.UserPanelVisible:
        return {
          ...state,
          visiblePanels: action.visible
            ? Panels.User
            : state.visiblePanels & ~Panels.User,
        };
      case CliftReducerAction.EditTenant:
        return {
          ...state,
          sidepanelContent:
            action.tenantID !== undefined ? (
              <EditTenant page={action.page} tenantID={action.tenantID} />
            ) : undefined,
        };
      case CliftReducerAction.EditTenantContacts:
        return {
          ...state,
          sidepanelContent:
            action.tenant !== undefined ? (
              <AddTenantContacts tenant={action.tenant} />
            ) : undefined,
        };
      case CliftReducerAction.AddTenant:
        return {
          ...state,
          sidepanelContent: <CreateTenant />,
        };
      case CliftReducerAction.AddCustomer:
        return {
          ...state,
          sidepanelContent: <CreateCustomer />,
        };
      case CliftReducerAction.SetCurrentCustomer:
        return {
          ...state,
          currentCustomer: action.customer,
        };
      case CliftReducerAction.EditCustomer:
        return {
          ...state,
          sidepanelContent: action.customer ? (
            <EditCustomer
              page={action.page}
              editingCustomer={action.customer}
            />
          ) : undefined,
        };
      case CliftReducerAction.AddCustomerLifts:
        return {
          ...state,
          sidepanelContent: action.customer ? (
            <AddCustomerLifts editingCustomer={action.customer} />
          ) : undefined,
        };
      case CliftReducerAction.FetchContacts:
        fetchContacts(action.customerID)
          .then((res) => {
            dispatchCliftState({
              type: CliftReducerAction.SetContacts,
              contacts: res,
            });
          })
          .catch((err) => {
            dispatchCliftState({
              type: CliftReducerAction.AddAlert,
              alert: t("contacts_list_http_fail", {
                ns: "alerts",
                code: getErrorCode(err),
              }),
            });
          });

        return {
          ...state,
        };
      case CliftReducerAction.FetchCustomers:
        fetchCustomers(state.currentTenant?.id)
          .then((res) => {
            dispatchCliftState({
              type: CliftReducerAction.SetCustomers,
              customers: res,
            });
          })
          .catch((err) => {
            dispatchCliftState({
              type: CliftReducerAction.SetCustomers,
              customers: [],
            });
            dispatchCliftState({
              type: CliftReducerAction.AddAlert,
              alert: t("customer_list_http_fail", {
                ns: "alerts",
                code: getErrorCode(err),
              }),
            });
          });

        return {
          ...state,
        };

      case CliftReducerAction.SetCustomers:
        return {
          ...state,
          customers: Object.values({
            ...Object.fromEntries(
              action.customers.map((customer) => [customer.id, customer]),
            ),
          }),
        };
      case CliftReducerAction.SetContacts:
        return {
          ...state,
          contacts: Object.values({
            ...Object.fromEntries(
              action.contacts.map((contact) => [contact.id, contact]),
            ),
          }),
        };
      case CliftReducerAction.AddUsers:
        return {
          ...state,
          // Override users with same id
          users: Object.values({
            ...Object.fromEntries(state.users.map((user) => [user.id, user])),
            ...Object.fromEntries(action.users.map((user) => [user.id, user])),
          }),
        };
      case CliftReducerAction.CloseSidePanel:
        return {
          ...state,
          sidepanelContent: undefined,
        };

      default:
        return { ...state };
    }
  }

  const [cliftState, dispatchCliftState] = useReducer(cliftReducer, {
    currentTenant: undefined,
    tenants: [],
    childTenants: undefined,
    alerts: [],
    currentCustomer: undefined,
    customers: undefined,
    users: [],
    contacts: [],
    sidepanelContent: undefined,
    tenantFetch: {},
    visiblePanels: 0,
  });

  useEffect(() => {
    fetch("/.auth/me")
      .then(async (response) => {
        const currentUser: ClientPrincipal | undefined = await response
          .json()
          .catch((error) => {
            // Handle errors here
            console.error(error);
          });
        if (currentUser) setCurrentUser(currentUser);
      })
      .catch((err) => {
        //TODO: proper error handling
        console.error(err);
      });
  }, []);

  return (
    <Provider
      value={{
        currentUser,
        createTenantPanelVisible,
        hasTenantRole,
        setCreateTenantPanelVisible,
        cliftState,
        dispatchCliftState,
      }}
    >
      {children}
    </Provider>
  );
};
