import { useTranslation } from "react-i18next";
import {
  useCliftContext,
  CliftReducerAction,
} from "../../context/clift-context/clift-context";
import { useEffect, useState } from "react";
import Page from "../../components/Page";
import { ReactComponent as PlusIcon } from "../../assets/Plus.svg";
import { CustomerList } from "./CustomerList";
import "./CustomersPage.css";
import { ConfirmDelete } from "../../components/ConfirmDelete";
import { getErrorCode } from "../../services/common";
import { deleteCustomer } from "../../services/customers-api";

export const CustomersPage = () => {
  const { t } = useTranslation();
  const { cliftState, dispatchCliftState } = useCliftContext();
  const [updateView, setUpdateView] = useState(false);
  const [selectedCustomerID, setSelectedCustomerID] = useState<number>();

  useEffect(() => {
    if (cliftState.currentTenant) {
      dispatchCliftState({
        type: CliftReducerAction.FetchCustomers,
      });
    }
  }, [cliftState.currentTenant, updateView]);

  if (!cliftState.currentTenant) {
    return <></>;
  }

  if (!cliftState.customers) {
    return <progress></progress>;
  }

  const handleDeleteCustomer = async () => {
    if (selectedCustomerID === undefined || !cliftState.currentTenant) return;

    await deleteCustomer(selectedCustomerID).catch((err) => {
      dispatchCliftState({
        type: CliftReducerAction.AddAlert,
        alert: t("delete_customer_http_fail", {
          ns: "alerts",
          code: getErrorCode(err),
        }),
      });
    });

    setSelectedCustomerID(undefined);
    setUpdateView(!updateView);
  };
  return (
    <Page
      title={t("customers", { ns: "customer" })}
      subtitle={t("customers_subtitle", { ns: "customer" })}
      button={
        <button
          className="button"
          type="button"
          onClick={() => {
            dispatchCliftState({
              type: CliftReducerAction.AddCustomer,
            });
          }}
        >
          <PlusIcon />

          {t("add_new_customer", { ns: "customer" })}
        </button>
      }
    >
      <>
        <CustomerList
          customers={cliftState.customers}
          setSelectedCustomerID={(customerID: number | undefined) => {
            setSelectedCustomerID(customerID);
          }}
        />

        {selectedCustomerID !== undefined && (
          <ConfirmDelete
            title={t("delete_customer_question", { ns: "dialogs" })}
            text={t("confirm_customer_delete_question", {
              ns: "dialogs",
              customer: selectedCustomerID,
            })}
            isOpen={selectedCustomerID !== undefined}
            onCancel={() => setSelectedCustomerID(undefined)}
            onConfirmed={handleDeleteCustomer}
          />
        )}
      </>
    </Page>
  );
};
