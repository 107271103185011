export const enum CliftApiErrorCodes {
  ERR_PARSE = -1,
  ERR_DOM = -2,
  ERR_INVALID = -3,
  ERR_UNKNOWN = -4,
}

export class CliftApiError extends Error {
  public originalCause: unknown;
  public constructor(statusCode: number, originalCause?: unknown) {
    super("Api error");
    this.originalCause = originalCause;
    this.cause = statusCode;
  }
}

export const handleError = (err: Error) => {
  if (err instanceof CliftApiError) {
    throw err;
  } else if (err instanceof SyntaxError) {
    throw new CliftApiError(CliftApiErrorCodes.ERR_PARSE, err.cause);
  } else if (err instanceof DOMException) {
    throw new CliftApiError(CliftApiErrorCodes.ERR_DOM, err.cause);
  } else if (err instanceof TypeError) {
    throw new CliftApiError(CliftApiErrorCodes.ERR_INVALID, err.cause);
  } else {
    throw new CliftApiError(CliftApiErrorCodes.ERR_UNKNOWN, err.cause);
  }
};

export const getErrorCode = (err: Error) => {
  return err instanceof CliftApiError
    ? err.cause
    : CliftApiErrorCodes.ERR_UNKNOWN;
};
