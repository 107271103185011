import {
  CliftReducerAction,
  useCliftContext,
} from "../../context/clift-context/clift-context";
import { useTranslation } from "react-i18next";
import SideModal from "../../components/SideModal";
import { AddTenantContactsForm } from "./AddTenantContactsForm";
import { getErrorCode } from "../../services/common";
import { useState } from "react";
import { TenantResponseFull } from "../../models/tenant";
import { Contact } from "../../models/contact";
import { updateTenant } from "../../services/tenants-api";

export interface AddTenantContactsProps {
  tenant: TenantResponseFull;
}

export const AddTenantContacts = ({ tenant }: AddTenantContactsProps) => {
  const { dispatchCliftState } = useCliftContext();
  const { t } = useTranslation();
  const [error, setError] = useState<string>();

  const closeSidePanel = () => {
    dispatchCliftState({
      type: CliftReducerAction.CloseSidePanel,
    });
    setError(undefined);
  };

  const onAddContacts = (tenant: TenantResponseFull, contacts: Contact[]) => {
    const updatedTenant = {
      ...tenant,
      contacts,
    };

    updateTenant(tenant, tenant.parentTenant?.id ?? null, updatedTenant)
      .then(() => {
        dispatchCliftState({
          type: CliftReducerAction.FetchTenants,
        });
        closeSidePanel();
      })
      .catch((err) => {
        setError(getErrorCode(err) as string);
      });
  };

  return (
    <SideModal
      title={t("add_contacts_title", { ns: "tenant" })}
      onClose={closeSidePanel}
    >
      <AddTenantContactsForm
        tenant={tenant}
        error={error}
        initialContacts={tenant.contacts ?? []}
        onSubmit={onAddContacts}
        onClose={closeSidePanel}
      />
    </SideModal>
  );
};
