import { Contact } from "../models/contact";
import { CliftApiError, handleError } from "./common";

export const fetchContacts = (customerId: number): Promise<Contact[]> =>
  fetch("/api/customers/" + customerId + "/contacts/", {
    method: "GET",
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw new CliftApiError(res.status);
      }
    })
    .catch((e) => handleError(e));
