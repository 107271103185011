import {
  CliftReducerAction,
  useCliftContext,
} from "../../context/clift-context/clift-context";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { createUser } from "../../services/users-api";
import { getErrorCode } from "../../services/common";

export const CreateUser = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { dispatchCliftState } = useCliftContext();
  const { t } = useTranslation();
  const userId = useRef<HTMLInputElement>(null);
  const name = useRef<HTMLInputElement>(null);
  const email = useRef<HTMLInputElement>(null);

  const getTenantId = () => {
    const tenantID =
      params.tenantID !== undefined ? parseInt(params.tenantID) : undefined;
    return tenantID !== undefined && !isNaN(tenantID) ? tenantID : undefined;
  };

  return (
    <>
      <div id="user">
        <div
          style={{
            margin: "50px",
          }}
        >
          <h1>{t("create_user", { ns: "user" })}</h1>
          <form
            onSubmit={async (e) => {
              e.preventDefault();

              const tenantID = getTenantId();
              if (tenantID) {
                createUser(tenantID, name.current!.value, email.current!.value)
                  .then(() => navigate("/tenant/" + tenantID))
                  .catch((err) =>
                    dispatchCliftState({
                      type: CliftReducerAction.AddAlert,
                      alert: t("user_create_http_fail", {
                        ns: "alerts",
                        code: getErrorCode(err),
                      }),
                    }),
                  );
              }
            }}
          >
            <fieldset>
              <label>
                {t("name")}
                <input type="text" ref={name} name="name" />
              </label>
              <br />
              <label>
                {t("business_id", { ns: "tenant" })}
                <input type="text" ref={userId} name="userId" required />
              </label>
              <br />
              <label>
                {t("email", { ns: "contact" })}
                <input type="email" ref={email} name="email" />
              </label>
            </fieldset>
            <input type="submit" value={t("create_user", { ns: "user" })} />
          </form>
        </div>
      </div>
    </>
  );
};
