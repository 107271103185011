import Home from "./home/Home";
import Report from "./reports/Report";
import Navbar from "../components/Navbar";
import { Topmenu } from "../components/Topmenu";
import "./App.css";
import { useTranslation } from "react-i18next";
import { Outlet, createBrowserRouter, RouterProvider } from "react-router-dom";
import { CreateTenant } from "./tenants/CreateTenant";
import { TenantDetail } from "./tenants/TenantDetail";
import { CreateUser } from "./tenants/CreateUser";
import { LiftListPage } from "./lifts/LiftList";
import { CreateLift } from "./lifts/CreateLift";
import { LiftDetails } from "./lifts/LiftDetails";
import "./i18nsetup";
import { AlertsPanel } from "../components/AlertsPanel";
import {
  CliftReducerAction,
  useCliftContext,
} from "../context/clift-context/clift-context";
import { UserPanel } from "../components/UserPanel";
import { useEffect } from "react";
import { CustomerDetail } from "./customers/CustomerDetail";
import { CustomersPage } from "./customers/CustomersPage";
import { TenantsPage } from "./tenants/TenantsPage";

const Layout = () => {
  const { currentUser, cliftState, dispatchCliftState } = useCliftContext();

  useEffect(() => {
    if (currentUser.clientPrincipal?.userRoles?.includes("authenticated")) {
      dispatchCliftState({
        type: CliftReducerAction.FetchTenants,
      });
    }
  }, [cliftState.currentTenant, currentUser.clientPrincipal?.userRoles]);

  return (
    <>
      <div
        id="app-container"
        className={cliftState.sidepanelContent ? "sidepanelopen" : ""}
        //https://stackoverflow.com/a/77153892
        {...{ inert: cliftState.sidepanelContent ? "" : undefined }}
      >
        <Navbar />
        <div
          style={{
            flexGrow: 1,
          }}
        >
          <Topmenu />
          <AlertsPanel />
          <UserPanel />
          <div className="app-container-content">
            <Outlet />
          </div>
        </div>
      </div>
      {cliftState.sidepanelContent ? cliftState.sidepanelContent : null}
    </>
  );
};

const PageNotFound = () => {
  const { t } = useTranslation();
  return <div>{t("page_not_found")}</div>;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/lifts",
        element: <LiftListPage />,
      },
      {
        path: "/lifts/create",
        element: <CreateLift />,
      },
      {
        path: "/lifts/:deviceID",
        element: <LiftDetails />,
      },
      {
        path: "/reports",
        element: <Report />,
      },
      {
        path: "/tenants/:tenantID",
        element: <TenantDetail />,
      },
      {
        path: "/tenants",
        element: <TenantsPage />,
      },
      {
        path: "/tenants/create",
        element: <CreateTenant />,
      },
      {
        path: "/users/create:tenantID",
        element: <CreateUser />,
      },
      {
        path: "/customers",
        element: <CustomersPage />,
      },
      {
        path: "/customers/:customerID",
        element: <CustomerDetail />,
      },
      {
        path: "*",
        element: <PageNotFound />,
      },
    ],
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
