import { Device } from "../models/device";
import { CliftApiError, handleError } from "./common";

export const fetchDevices = (): Promise<Device[]> =>
  fetch("/api/devices", {
    method: "GET",
  })
    .then((res) => {
      if (res.ok) {
        return res.json().then((json: Device[]) => {
          return json;
        });
      } else {
        throw new CliftApiError(res.status);
      }
    })
    .catch((e) => handleError(e));

export const fetchDevice = (deviceId: number): Promise<Device> =>
  fetch("/api/device/" + deviceId, {
    method: "GET",
  })
    .then((res) => {
      if (res.ok) {
        return res.json().then((json: Device) => {
          return json;
        });
      } else {
        throw new CliftApiError(res.status);
      }
    })
    .catch((e) => handleError(e));

export const createDevice = (
  id: number,
  activated: number,
  productID: string,
  serialNumber: number,
  model = "",
  variant = "",
  billingStartDate: Date,
  billingEndDate: Date,
  installationLocation = "",
): Promise<void> => {
  return fetch("/api/devices/add", {
    method: "POST",
    body: JSON.stringify({
      id,
      activated,
      product_id: productID,
      serial_number: serialNumber,
      model: model?.length > 0 ? model : null,
      variant: variant?.length > 0 ? variant : null,
      billing_start_date:
        billingStartDate !== undefined ? billingStartDate : null,
      billing_end_date: billingEndDate !== undefined ? billingEndDate : null,
      installation_location:
        installationLocation?.length > 0 ? installationLocation : null,
    }),
  })
    .then((res) => {
      if (!res.ok) {
        throw new CliftApiError(res.status);
      }
    })
    .catch((e) => handleError(e));
};
