import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Device } from "../../models/device";
import { fetchDevice } from "../../services/devices-api";
import {
  CliftReducerAction,
  useCliftContext,
} from "../../context/clift-context/clift-context";
import { getErrorCode } from "../../services/common";

export const LiftDetails = () => {
  const params = useParams();
  const { dispatchCliftState } = useCliftContext();
  const [device, setDevice] = useState<Device>();
  const { t } = useTranslation();

  const getDeviceId = () => {
    const deviceID =
      params.deviceID !== undefined ? parseInt(params.deviceID) : undefined;
    return deviceID !== undefined && !isNaN(deviceID) ? deviceID : undefined;
  };

  useEffect(() => {
    const deviceId = getDeviceId();

    if (deviceId !== undefined) {
      fetchDevice(deviceId)
        .then((dev) => {
          setDevice(dev);
        })
        .catch((err) => {
          dispatchCliftState({
            type: CliftReducerAction.AddAlert,
            alert: t("lift_list_http_fail", {
              ns: "alerts",
              code: getErrorCode(err),
            }),
          });
        });
    }
  }, [params.deviceID]);

  if (!device) {
    return <progress></progress>;
  }

  return (
    <>
      <table id="lift_details">
        <caption>{t("lift_details", { ns: "lift" })}</caption>
        <tbody>
          <tr>
            <th scope="row">{t("id", { ns: "lift" })}</th>
            <td>{device?.id}</td>
          </tr>
          <tr>
            <th scope="row">{t("activated", { ns: "lift" })}</th>
            <td>{device?.activated}</td>
          </tr>
          <tr>
            <th scope="row">{t("product_id", { ns: "lift" })}</th>
            <td>{device?.product_id}</td>
          </tr>
          <tr>
            <th scope="row">{t("serial_number", { ns: "lift" })}</th>
            <td>{device?.serial_number}</td>
          </tr>
          <tr>
            <th scope="row">{t("model", { ns: "lift" })}</th>
            <td>{device?.model}</td>
          </tr>
          <tr>
            <th scope="row">{t("variant", { ns: "lift" })}</th>
            <td>{device?.variant}</td>
          </tr>
          <tr>
            <th scope="row">{t("billing_start_date", { ns: "lift" })}</th>
            <td>{device?.billing_start_date?.toString()}</td>
          </tr>
          <tr>
            <th scope="row">{t("billing_end_date", { ns: "lift" })}</th>
            <td>{device?.billing_end_date?.toString()}</td>
          </tr>
          <tr>
            <th scope="row">{t("installation_location", { ns: "lift" })}</th>
            <td>{device?.installation_location}</td>
          </tr>
        </tbody>
      </table>
      <br /> <br />
    </>
  );
};
