import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./LiftList.css";
import { Link } from "react-router-dom";
import {
  CliftReducerAction,
  useCliftContext,
} from "../../context/clift-context/clift-context";
import { Device } from "../../models/device";
import { fetchDevices } from "../../services/devices-api";
import { getErrorCode } from "../../services/common";

const DeviceList = () => {
  const [devices, setDevices] = useState<Device[]>([]);
  const { dispatchCliftState } = useCliftContext();
  const { t } = useTranslation();

  useEffect(() => {
    fetchDevices()
      .then((res) => {
        setDevices(res);
      })
      .catch((err) => {
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("lift_list_http_fail", {
            ns: "alerts",
            code: getErrorCode(err),
          }),
        });
      });
  }, []);

  return (
    <>
      {devices.map((device) => {
        return (
          <tr key={device.id}>
            <td>
              <Link to={device.id.toString()}>{device.id}</Link>
            </td>
            <td>{device.activated}</td>
            <td>{device.product_id}</td>
            <td>{device.serial_number}</td>
            <td>{device.model}</td>
            <td>{device.variant}</td>
            <td>{device.billing_start_date?.toString()}</td>
            <td>{device.billing_end_date?.toString()}</td>
            <td>{device.installation_location}</td>
          </tr>
        );
      })}
    </>
  );
};

export const LiftListPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <div id="lift-list">
        <div id="lift-list-body">
          <h1>{t("lifts", { ns: "lift" })}</h1>
          <Link to="create">{t("create_lift", { ns: "lift" })}</Link>

          <table>
            <caption>{t("lift_list", { ns: "lift" })}</caption>
            <thead>
              <tr>
                <th>{t("id", { ns: "lift" })}</th>
                <th>{t("activated", { ns: "lift" })}</th>
                <th>{t("product_id", { ns: "lift" })}</th>
                <th>{t("serial_number", { ns: "lift" })}</th>
                <th>{t("model", { ns: "lift" })}</th>
                <th>{t("variant", { ns: "lift" })}</th>
                <th>{t("billing_start_date", { ns: "lift" })}</th>
                <th>{t("billing_end_date", { ns: "lift" })}</th>
                <th>{t("installation_location", { ns: "lift" })}</th>
              </tr>
            </thead>
            <tbody>
              <DeviceList />
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
