import { Contact } from "../../models/contact";
import { ReactComponent as CallIcon } from "../../assets/Call.svg";
import "./TenantDetailContacts.css";

export interface TenantDetailContactssProps {
  contacts: Contact[];
}

export const TenantDetailContacts = ({
  contacts,
}: TenantDetailContactssProps) => {
  return (
    <div className="tenant-detail-contacts">
      {contacts.map((contact, i) => (
        <div key={i} className="tenant-detail-contacts-item">
          <div className="tenant-detail-contacts-item-area">
            <div className="tenant-detail-contacts-item-content">
              <CallIcon />
              <h3>{contact.displayName}</h3>
              <h4>{contact.phoneNr}</h4>
            </div>
          </div>
          <div className="tenant-detail-contacts-item-line" />
        </div>
      ))}
    </div>
  );
};
