import FormField from "../../components/FormField";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as PlusIcon } from "../../assets/Plus.svg";
import { ReactComponent as DeleteIcon } from "../../assets/Delete.svg";
import { Contact, ContactType } from "../../models/contact";
import { TenantResponseFull } from "../../models/tenant";
import "./AddTenantContactsForm.css";
import { ConfirmDialog } from "../../components/ConfirmDialog";

export interface AddTenantContactsFormProps {
  tenant: TenantResponseFull;
  error: string | undefined;
  initialContacts?: Contact[];
  onSubmit: (tenant: TenantResponseFull, contacts: Contact[]) => void;
  onClose: () => void;
}

export const AddTenantContactsForm = ({
  tenant,
  error,
  initialContacts = [],
  onSubmit,
  onClose,
}: AddTenantContactsFormProps) => {
  const { t } = useTranslation();
  const [contacts, setContacts] = useState<Contact[]>(initialContacts);
  const [canceled, setCanceled] = useState(false);
  const [hasChanged, setChanged] = useState(false);
  const addTenantContactsForm = useRef<HTMLFormElement>(null);
  const newName = useRef<HTMLInputElement>(null);
  const newPhoneNr = useRef<HTMLInputElement>(null);

  const getErrorText = (errorCode: string | number) => {
    return t("adding_contact_failed", {
      ns: "tenant",
      code: errorCode,
    });
  };

  const onHandleContactChanged = (
    name: string,
    index: number,
    value: string,
  ) => {
    setChanged(true);

    setContacts(
      contacts.map((c, i) => {
        if (i === index)
          return {
            ...c,
            [name]: value,
          };
        return c;
      }),
    );
  };

  const addContactFromForm = (): boolean => {
    if (
      !newName.current ||
      !newName.current?.checkValidity() ||
      !newPhoneNr.current ||
      !newPhoneNr.current?.checkValidity()
    ) {
      return false;
    }
    const displayName = newName.current!.value;
    const phoneNr = newPhoneNr.current!.value;
    const newContacts = [
      ...contacts,
      {
        displayName,
        phoneNr,
        contactType: ContactType.SUPPORT,
        firstName: "",
        lastName: "",
      },
    ];
    setContacts(newContacts);
    newName.current!.value = "";
    newPhoneNr.current!.value = "";
    return true;
  };

  useEffect(() => {
    setContacts(initialContacts ?? []);
  }, [initialContacts]);

  return (
    <div className="add-tenant-contacts-form">
      <form
        ref={addTenantContactsForm}
        onSubmit={(e) => {
          e.preventDefault();
          addContactFromForm();
          onSubmit(tenant, contacts);
        }}
      >
        <div className="add-tenant-contacts-form-page">
          <div className="add-tenant-contacts-form-creation-row">
            <div>
              <h3>{t("add_contacts_subtilte", { ns: "tenant" })}</h3>
              <h4>{t("add_contacts_note", { ns: "tenant" })}</h4>
            </div>

            <button
              className="button"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setContacts([
                  ...contacts,
                  {
                    displayName: "",
                    phoneNr: "",
                    contactType: ContactType.SUPPORT,
                    firstName: "",
                    lastName: "",
                  },
                ]);
              }}
            >
              <PlusIcon />
              {t("add_contact_lift_btn", { ns: "tenant" })}
            </button>
          </div>

          {contacts.map((contact, i) => (
            <div
              key={i}
              className="add-tenant-contacts-form-creation-input-row"
            >
              <FormField
                title={t("contact_name", { ns: "tenant" })}
                name="displayName"
                required
                autoComplete="off"
                maxLength={10}
                value={contact.displayName}
                onValueChanged={(name, value) =>
                  onHandleContactChanged(name, i, value as string)
                }
              />
              <FormField
                title={t("contact_phone_number", { ns: "tenant" })}
                name="phoneNr"
                required
                maxLength={20}
                autoComplete="off"
                value={contact.phoneNr}
                onValueChanged={(name, value) =>
                  onHandleContactChanged(name, i, value as string)
                }
              />
              <button
                className="add-tenant-contacts-form-delete-btn"
                title={t("delete", { ns: "translation" })}
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  if (contact) setContacts(contacts.toSpliced(i, 1));
                }}
              >
                <DeleteIcon />
              </button>
            </div>
          ))}

          <div className="add-tenant-contacts-form-errors">
            {error && getErrorText(error)}
          </div>

          <div className="add-tenant-contacts-form-button-panel">
            <button
              type="button"
              className="button"
              onClick={(e) => {
                e.preventDefault();
                if (hasChanged) {
                  setCanceled(true);
                } else {
                  onClose();
                }
              }}
            >
              {t("cancel")}
            </button>
            <button type="submit" className="button" id="submit_button">
              {t("save", { ns: "translation" })}
            </button>
          </div>
        </div>
      </form>

      <ConfirmDialog
        isOpen={canceled}
        onCancel={() => {
          setCanceled(false);
        }}
        onConfirm={() => {
          onClose();
          setCanceled(false);
        }}
      />
    </div>
  );
};
