import {
  CliftReducerAction,
  useCliftContext,
} from "../../context/clift-context/clift-context";
import editIcon from "../../assets/Edit.svg";
import deleteIcon from "../../assets/Delete.svg";
import { useTranslation } from "react-i18next";
import { fetchCustomer } from "../../services/customers-api";

export const CustomerOptionsMenu = ({
  customerID,
  setSelectedCustomerID,
}: {
  customerID: number;
  setSelectedCustomerID(customerID: number): void;
}) => {
  const { dispatchCliftState } = useCliftContext();
  const { t } = useTranslation();

  return (
    <>
      <div className="dropdown" id={"customer_" + customerID}>
        <button className="dropbutton">...</button>
        <div className="dropdown-items">
          <p
            className="link"
            onClick={() => {
              return fetchCustomer(customerID).then((res) => {
                dispatchCliftState({
                  type: CliftReducerAction.EditCustomer,
                  customer: res,
                  customerID,
                });
              });
            }}
          >
            <img alt="" className="edit-icon" src={editIcon} />
            {t("edit")}
          </p>
          <p
            className="link"
            onClick={() => {
              setSelectedCustomerID(customerID);
            }}
          >
            <img alt="Delete" className="delete-icon" src={deleteIcon} />
            {t("delete", { ns: "customer" })}
          </p>
        </div>
      </div>
    </>
  );
};
