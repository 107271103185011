import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./CreateLift.css";
import {
  CliftReducerAction,
  useCliftContext,
} from "../../context/clift-context/clift-context";
import { createDevice } from "../../services/devices-api";
import { getErrorCode } from "../../services/common";

export const CreateLift = () => {
  const { t } = useTranslation();
  const { dispatchCliftState } = useCliftContext();

  const navigate = useNavigate();
  const id = useRef<HTMLInputElement>(null);
  const activated = useRef<HTMLInputElement>(null);
  const productID = useRef<HTMLInputElement>(null);
  const serialNumber = useRef<HTMLInputElement>(null);
  const model = useRef<HTMLInputElement>(null);
  const variant = useRef<HTMLInputElement>(null);
  const billingStartDate = useRef<HTMLInputElement>(null);
  const billingEndDate = useRef<HTMLInputElement>(null);
  const installationLocation = useRef<HTMLInputElement>(null);

  return (
    <>
      <div id="create-lift">
        <div id="create-lift-list-body">
          <h1>{t("create_lift", { ns: "lift" })}</h1>
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              createDevice(
                parseInt(id.current!.value),
                activated.current?.checked ? 1 : 0,
                productID.current!.value,
                parseInt(serialNumber.current!.value),
                model.current?.value,
                variant.current?.value,
                billingStartDate.current!.valueAsDate!,
                billingEndDate.current!.valueAsDate!,
                installationLocation.current?.value,
              )
                .then(() => navigate("/lifts/"))
                .catch((err) => {
                  dispatchCliftState({
                    type: CliftReducerAction.AddAlert,
                    alert: t("lift_create_http_fail", {
                      ns: "alerts",
                      code: getErrorCode(err),
                    }),
                  });
                });
            }}
          >
            <fieldset>
              <label>
                {t("id", { ns: "lift" })}
                <input type="number" ref={id} name="id" required />
              </label>
              <br />
              <label>
                {t("activated", { ns: "lift" })}
                <input type="checkbox" name="activated" ref={activated} />
              </label>
              <br />
              <label>
                {t("product_id", { ns: "lift" })}
                <input type="text" ref={productID} name="productID" required />
              </label>
              <br />
              <label>
                {t("serial_number", { ns: "lift" })}
                <input
                  type="text"
                  ref={serialNumber}
                  name="serialNumber"
                  required
                />
              </label>
              <br />
              <label>
                {t("model", { ns: "lift" })}
                <input type="text" ref={model} name="model" />
              </label>
              <br />
              <label>
                {t("variant", { ns: "lift" })}
                <input type="text" ref={variant} name="variant" />
              </label>
              <br />
              <label>
                {t("billing_start_date", { ns: "lift" })}
                <input
                  type="date"
                  ref={billingStartDate}
                  name="billingStartDate"
                />
              </label>
              <br />
              <label>
                {t("billing_end_date", { ns: "lift" })}
                <input type="date" ref={billingEndDate} name="billingEndDate" />
              </label>
              <br />
              <label>
                {t("installation_location", { ns: "lift" })}
                <input
                  type="text"
                  ref={installationLocation}
                  name="installationLocation"
                />
              </label>
            </fieldset>
            <input type="submit" value={t("create_lift", { ns: "lift" })} />
          </form>
        </div>
      </div>
    </>
  );
};
