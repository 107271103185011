import { DeviceListResponse } from "../../models/device";
import { ReactComponent as ForwardIcon } from "../../assets/Forward.svg";
import "./CustomerDetailDevices.css";

export interface CustomerDetailDevicesProps {
  devices: DeviceListResponse[];
  onItemSelected: (index: number) => void;
}

export const CustomerDetailDevices = ({
  devices,
  onItemSelected,
}: CustomerDetailDevicesProps) => {
  const onItemClicked = (e: React.MouseEvent, index: number) => {
    e.preventDefault();
    onItemSelected(index);
  };

  return (
    <div className="customer-detail-devices">
      {devices.map((device, i) => (
        <div key={i} className="customer-detail-devices-item">
          <div className="customer-detail-devices-item-area">
            <div className="customer-detail-devices-item-content">
              <header>
                <h3>{device.nickname}</h3>
                <h4>{device.serialNumber}</h4>
              </header>
              <div className="customer-detail-devices-item-status">
                <span>Active</span>
              </div>
            </div>
            <div
              className="customer-detail-devices-item-btn"
              onClick={(e) => onItemClicked(e, i)}
            >
              <ForwardIcon />
            </div>
          </div>
          <div className="customer-detail-devices-item-line" />
        </div>
      ))}
    </div>
  );
};
