import { TenantListItem } from "../models/tenant-list-item";
import {
  TenantResponseFull,
  TenantFormValues,
  TenantType,
} from "../models/tenant";
import { CliftApiError, handleError } from "./common";

export const fetchTenants = (parentId?: number): Promise<TenantListItem[]> =>
  fetch(
    typeof parentId !== "undefined"
      ? `/api/tenant/${parentId}/childtenants`
      : "/api/tenant/",
    {
      method: "GET",
    },
  )
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw new CliftApiError(res.status);
      }
    })
    .catch((e) => handleError(e));

export const fetchTenant = (tenantId: number): Promise<TenantResponseFull> =>
  fetch(`/api/tenant/${tenantId}`, {
    method: "GET",
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw new CliftApiError(res.status);
      }
    })
    .catch((e) => handleError(e));

export const createTenant = (
  formValues: TenantFormValues,
  parentTenant: number,
  type: TenantType,
): Promise<void> =>
  fetch("/api/tenant", {
    method: "POST",
    body: JSON.stringify({
      name: formValues.name?.length ? formValues.name : null,
      businessId: formValues.businessId?.length ? formValues.businessId : null,
      address: formValues.address?.length ? formValues.address : null,
      phoneNr: formValues.phoneNr?.length ? formValues.phoneNr : null,
      email: formValues.email?.length ? formValues.email : null,
      tenantType: type,
      parentTenantId: parentTenant,
      admins: formValues.admins?.map((a) => a.email) ?? [],
      contacts: formValues.contacts ?? [],
    }),
  })
    .then((res) => {
      if (!res.ok) throw new CliftApiError(res.status);
    })
    .catch((e) => handleError(e));

export const deleteTenant = (tenantId: number): Promise<void> =>
  fetch(`/api/tenant/${tenantId}`, {
    method: "DELETE",
  })
    .then((res) => {
      if (!res.ok) {
        throw new CliftApiError(res.status);
      }
    })
    .catch((e) => handleError(e));

export const updateTenant = (
  tenant: TenantResponseFull,
  parentID: number | null,
  formValues: TenantFormValues,
): Promise<void> =>
  fetch(`/api/tenant/${tenant.id}`, {
    method: "PUT",
    body: JSON.stringify({
      name: formValues.name?.length ? formValues.name : null,
      businessId: formValues.businessId?.length ? formValues.businessId : null,
      address: formValues.address?.length ? formValues.address : null,
      phoneNr: formValues.phoneNr?.length ? formValues.phoneNr : null,
      email: formValues.email?.length ? formValues.email : null,
      tenantType: tenant.tenantType,
      parentTenantId: parentID,
      contacts: formValues.contacts ?? [],
      admins: formValues.admins?.map((a) => a.email) ?? [],
    }),
  })
    .then((res) => {
      if (!res.ok) {
        throw new CliftApiError(res.status);
      }
    })
    .catch((e) => handleError(e));

export const getTenantAdmins = (
  tenantId: number,
): Promise<{ roleId: number; email: string }[]> =>
  fetch(`/api/tenant/${tenantId}/admins`, {
    method: "GET",
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw new CliftApiError(res.status);
      }
    })
    .catch((e) => handleError(e));
