import { useTranslation } from "react-i18next";

const Report = () => {
  const { t } = useTranslation();
  return (
    <>
      <h1>{t("reports")}</h1>
    </>
  );
};

export default Report;
