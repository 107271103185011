import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  CliftReducerAction,
  useCliftContext,
} from "../../context/clift-context/clift-context";
import { fetchTenant } from "../../services/tenants-api";
import { TenantResponseFull, TenantType } from "../../models/tenant";
import { getErrorCode } from "../../services/common";
import Page from "../../components/Page";
import Card, {
  CardAddressInfo,
  CardContainer,
  CardDetail,
  CardType,
} from "../../components/Card";
import { ReactComponent as BuildingIcon } from "../../assets/Building.svg";
import { ReactComponent as ProfileIcon } from "../../assets/Profile.svg";
import { NoItems } from "../../components/NoItems";
import { TenantDetailContacts } from "./TenantDetailContacts";

export const TenantDetail = () => {
  const { cliftState, dispatchCliftState } = useCliftContext();
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [tenant, setTenant] = useState<TenantResponseFull>();
  const { t } = useTranslation();

  const getTenantId = () => {
    const tenantID =
      params.tenantID !== undefined ? parseInt(params.tenantID) : undefined;
    return tenantID !== undefined && !isNaN(tenantID) ? tenantID : undefined;
  };

  const handleEditTenant = () => {
    dispatchCliftState({
      type: CliftReducerAction.EditTenant,
      tenantID: tenant?.id,
    });
  };

  const handleEditAdmins = () => {
    dispatchCliftState({
      type: CliftReducerAction.EditTenant,
      tenantID: tenant?.id,
      page: 1,
    });
  };

  const handleEditContacts = () => {
    if (!tenant) return;

    dispatchCliftState({
      type: CliftReducerAction.EditTenantContacts,
      tenant: tenant,
    });
  };

  useEffect(() => {
    const tenantID = getTenantId();
    if (tenantID !== undefined && !cliftState.sidepanelContent) {
      setLoading(true);
      fetchTenant(tenantID)
        .then((res) => {
          setLoading(false);
          setTenant(res);
        })
        .catch((err) => {
          setLoading(false);
          dispatchCliftState({
            type: CliftReducerAction.AddAlert,
            alert: t("tenant_http_fail", {
              ns: "alerts",
              code: getErrorCode(err),
            }),
          });
        });
    }
  }, [params.tenantID, cliftState.sidepanelContent]);

  if (loading) {
    return <progress></progress>;
  }

  return (
    <Page
      title={tenant?.name ?? ""}
      subtitle={
        tenant?.id ? t("id", { ns: "translation" }) + " - " + tenant?.id : ""
      }
    >
      {tenant && (
        <CardContainer>
          <Card
            title={
              cliftState.currentTenant?.tenantType === TenantType.ROOT
                ? t("market_tenant", { ns: "tenant" })
                : t("tenant", { ns: "tenant" })
            }
            onEdit={handleEditTenant}
          >
            <CardDetail icon={<BuildingIcon />} value={tenant.name} />
            <CardDetail icon="VAT" value={tenant.businessId} />
            <CardAddressInfo
              street={tenant.address ?? ""}
              zip=""
              city=""
              country=""
            />
          </Card>
          <Card
            title={t("tenant_admins", { ns: "tenant" })}
            subtitle={t("tenant_detail_admins_title", { ns: "tenant" })}
            onEdit={handleEditAdmins}
            footer={t("tenant_detail_admins_summary", {
              ns: "tenant",
              count: tenant.admins?.length ?? 0,
            })}
          >
            {tenant.admins &&
              tenant.admins.map((admin, i) => (
                <CardDetail
                  key={i}
                  icon={<ProfileIcon />}
                  value={admin.email}
                />
              ))}
          </Card>
          <Card
            title={t("tenant_support_numbers_title", { ns: "tenant" })}
            subtitle={t("tenant_support_numbers_subtitle", { ns: "tenant" })}
            cardType={CardType.DOUBLE}
            onEdit={handleEditContacts}
          >
            {tenant.contacts && tenant.contacts?.length > 0 ? (
              <TenantDetailContacts contacts={tenant.contacts ?? []} />
            ) : (
              <NoItems
                title={t("no_support_numbers_added", { ns: "tenant" })}
                subtitle={t("no_support_numbers_added_hint", { ns: "tenant" })}
                button={
                  <button
                    className="button"
                    type="button"
                    onClick={handleEditContacts}
                  >
                    {t("add_new_support_numbers_btn", { ns: "tenant" })}
                  </button>
                }
              />
            )}
          </Card>
        </CardContainer>
      )}
    </Page>
  );
};
