import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCliftContext } from "../../context/clift-context/clift-context";
import { ReactComponent as PlusIcon } from "../../assets/Plus.svg";
import { ReactComponent as DeleteIcon } from "../../assets/Delete.svg";
import { ConfirmDialog } from "../../components/ConfirmDialog";
import FormField from "../../components/FormField";
import "./TenantForm.css";
import {
  TenantResponseFull,
  TenantAdmin,
  TenantFormValues,
  TenantType,
} from "../../models/tenant";

export const enum CreateTenantPages {
  TenantPage,
  TenantAdminPage,
}

export interface TenantFormProps {
  onSubmit: (
    tenant: Omit<TenantResponseFull, "id" | "tenant">,
    admins: TenantAdmin[],
  ) => Promise<void>;
  onClose: () => void;
  onSwitchPage: (pageIndex: number) => void;
  pageIndex: number;
  initialValues?: TenantFormValues;
  editingTenant?: TenantResponseFull | undefined;
  showSaveOnFirstPage?: boolean;
}

const adminFieldPrefix = "email_";

export const TenantForm = ({
  onSubmit,
  onClose,
  onSwitchPage,

  pageIndex,
  initialValues = {
    name: null,
    address: null,
    businessId: "",
    email: null,
    phoneNr: null,
    region: null,
    tenantType: TenantType.COUNTRY,
    parentTenant: null,
    contacts: [],
    admins: [],
  },
  editingTenant,
  showSaveOnFirstPage = false,
}: TenantFormProps) => {
  const { t } = useTranslation();
  const [tenant, setTenant] = useState(initialValues);
  const [canceled, setCanceled] = useState(false);
  const [hasChanged, setChanged] = useState(false);
  const { cliftState } = useCliftContext();
  const newAdmin = useRef<HTMLInputElement>(null);
  const form = useRef<HTMLFormElement>(null);
  const admins = useRef<string[]>([]);
  const [adminsState, setAdminsState] = useState<string[]>([]);

  const onHandleFieldChanged = (name: string, value: string | number) => {
    setChanged(true);
    setTenant({
      ...tenant,
      [name]: value,
    });
  };

  const onHandleAdminChanged = (name: string, value: string | number) => {
    setChanged(true);
    const index = parseInt(name.replace(adminFieldPrefix, ""));
    admins.current[index] = value as string;
    setAdmins(admins.current);
  };

  const setAdmins = (adminsList: string[]) => {
    admins.current = [...adminsList];
    setAdminsState([...adminsList]);
  };

  const addAdminFromForm = (): boolean => {
    if (!newAdmin.current?.checkValidity() || !newAdmin.current.value) {
      return false;
    }
    const adminEmail = newAdmin.current!.value;
    const newAdminsList = [...admins.current, adminEmail];
    setAdmins(newAdminsList);
    newAdmin.current!.value = "";
    return true;
  };

  useEffect(() => {
    setAdmins(editingTenant?.admins?.map((admin) => admin.email) ?? []);
  }, [editingTenant]);

  return (
    <div className="tenant-form">
      <form
        ref={form}
        onSubmit={(e) => {
          e.preventDefault();
          addAdminFromForm();
          onSubmit(
            tenant,
            admins.current.map((email) => ({ email })),
          );
        }}
      >
        {pageIndex === CreateTenantPages.TenantPage && (
          <div className="tenant-form-tenant-page">
            <div id="input-container" className="tenant-form-inputs">
              <FormField
                title={t("tenant_id", { ns: "tenant" })}
                name="id"
                disabled
                value={editingTenant?.id}
                onValueChanged={onHandleFieldChanged}
                hintText={t("tenant_id_hint", { ns: "tenant" })}
              />

              <FormField
                title={t("name")}
                name="name"
                required
                value={tenant.name ?? ""}
                onValueChanged={onHandleFieldChanged}
              />

              <FormField
                title={t("business_id", { ns: "tenant" })}
                name="businessId"
                value={tenant.businessId ?? ""}
                onValueChanged={onHandleFieldChanged}
                required
              />

              <FormField
                title={t("address", { ns: "contact" })}
                name="address"
                value={tenant.address ?? ""}
                onValueChanged={onHandleFieldChanged}
              />

              <FormField
                title={t("phone", { ns: "contact" })}
                name="phoneNr"
                required
                type="tel"
                value={tenant.phoneNr ?? ""}
                onValueChanged={onHandleFieldChanged}
              />

              <FormField
                title={t("email", { ns: "contact" })}
                name="email"
                type="email"
                value={tenant.email ?? ""}
                onValueChanged={onHandleFieldChanged}
              />
            </div>

            <div className="tenant-form-button-panel">
              <button
                type="button"
                className="button"
                id="cancel_button"
                onClick={(e) => {
                  e.preventDefault();
                  if (hasChanged) {
                    setCanceled(true);
                  } else {
                    onClose();
                  }
                }}
              >
                {t("cancel")}
              </button>
              {showSaveOnFirstPage && (
                <button type="submit" className="button" id="submit_button">
                  {t("save", { ns: "translation" })}
                </button>
              )}
              <button
                className="button"
                id="next_button"
                onClick={() => {
                  if (form.current?.reportValidity()) {
                    onSwitchPage(CreateTenantPages.TenantAdminPage);
                  }
                }}
              >
                {t("next")}
              </button>
            </div>
          </div>
        )}

        {pageIndex === CreateTenantPages.TenantAdminPage && (
          <div className="tenant-form-admin-page">
            <div className="tenant-form-creation-row">
              <div>
                <h3>{t("tenant_admins", { ns: "tenant" })}</h3>
                <h4>{t("tenant_admins_hint", { ns: "tenant" })}</h4>
              </div>

              <button
                className="button create-admin-button"
                id="create_admin_button"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  addAdminFromForm();
                }}
              >
                <PlusIcon />
                {t("new_admin", { ns: "tenant" })}
              </button>
            </div>
            <FormField
              title={t("email", { ns: "contact" })}
              name="email"
              type="email"
              autoComplete="off"
              required={admins.current.length === 0}
              ref={newAdmin}
            />
            <div className="tenant-form-admin-users">
              {adminsState.map((admin, i) => (
                <div key={i} className="tenant-form-admin-user-item">
                  <FormField
                    title={t("email", { ns: "contact" })}
                    name={adminFieldPrefix + i}
                    type="email"
                    autoComplete="off"
                    value={admin}
                    onValueChanged={onHandleAdminChanged}
                    required={admins.current.length === 0}
                  />
                  <button
                    className="delete-admin-button"
                    title={t("save", { ns: "translation" })}
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      admins.current = admins.current.toSpliced(i, 1);
                      setAdmins(admins.current);
                    }}
                  >
                    <DeleteIcon />
                  </button>
                </div>
              ))}
            </div>
            <div className="tenant-form-admin-email">
              <datalist id="users">
                {editingTenant
                  ? cliftState.users
                      .filter((user) => user.tenant?.id == editingTenant!.id)
                      .map((user) => (
                        <option key={user.id}>{user.email}</option>
                      ))
                  : null}
              </datalist>
            </div>
            <div className="tenant-form-button-panel">
              <button
                className="button"
                id="cancel_admin_button"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  if (hasChanged) {
                    setCanceled(true);
                  } else {
                    onClose();
                  }
                }}
              >
                {t("cancel")}
              </button>
              <button type="submit" className="button" id="submit_button">
                {t("save", { ns: "translation" })}
              </button>
              <button
                className="button"
                id="back_button"
                onClick={() => {
                  onSwitchPage(CreateTenantPages.TenantPage);
                }}
              >
                {t("back")}
              </button>
            </div>
          </div>
        )}
      </form>

      <ConfirmDialog
        isOpen={canceled}
        onCancel={() => {
          setCanceled(false);
        }}
        onConfirm={() => {
          onClose();
          setCanceled(false);
        }}
      />
    </div>
  );
};
