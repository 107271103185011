import { ReactNode, MouseEvent } from "react";
import { ReactComponent as CloseIcon } from "../assets/Close.svg";
import "./SideModal.css";

export interface SideModalProps {
  title: string;
  children?: ReactNode;
  showPager?: boolean;
  pageIndex?: number;
  pageCount?: number;
  onClose: () => void;
}

const SideModal = ({
  title,
  children,
  showPager,
  pageIndex,
  pageCount,
  onClose,
}: SideModalProps) => {
  const onHandleClose = (e: MouseEvent) => {
    e.preventDefault();
    onClose();
  };

  return (
    <div className="modal-container">
      <div className="modal-background" />
      <aside className="side-modal">
        <button
          title="close"
          className="side-modal-close"
          onClick={onHandleClose}
        >
          <CloseIcon />
        </button>
        <header>
          <div className="side-modal-title">{title}</div>
          {showPager && (
            <PageSelector index={pageIndex ?? 0} pages={pageCount ?? 0} />
          )}
        </header>
        <div className="side-modal-content">{children}</div>
      </aside>
    </div>
  );
};

interface PageSelectorProps {
  index: number;
  pages: number;
}

const PageSelector = ({ index, pages }: PageSelectorProps) => {
  const indicators = [];
  for (let i = 0; i < pages; ++i) {
    indicators.push(
      <div
        className={
          "side-modal-pager-indicator " +
          (index === i ? "side-modal-pager-indicator-active" : "")
        }
      />,
    );
  }

  return <div className="side-modal-pager">{indicators}</div>;
};

export default SideModal;
